@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~sweetalert2/dist/sweetalert2.css';


@layer base {
  .nav-item-active {
    @apply bg-gray-50 bg-opacity-20;
  }
  .nav-item {
    @apply hover:bg-gray-50 hover:bg-opacity-20;
  }
  .tooltip {
    @apply invisible absolute;
  }
  
  .has-tooltip:hover .tooltip {
    @apply visible z-50
  }
  
  
  
}


.file-style {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

/* __play_start_utilities__ */

.file-style::-webkit-file-upload-button {
  margin-right: 1.5rem;
  border-radius: 9999px;
  border-width: 0px;
  background-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.file-style::file-selector-button {
  margin-right: 1.5rem;
  border-radius: 9999px;
  border-width: 0px;
  background-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

/* __play_end_utilities__ */

